import React, { useState } from "react";
import "./Experiencia.css"
import $ from "jquery"

const Experiencia = () => {
    let prev = "<"
    let next = ">"

    const cargarImagen = require.context("./Fotos", true);


    const [Siguiente, setSiguiente] = useState(1)

    function siguiente() {
        if (Siguiente === 38) {
            setSiguiente(1)
        } else {
            setSiguiente(Siguiente + 1)
            $(`#imagen${Siguiente}`).removeClass("activado");

        }

    }

    function atras() {

        if (Siguiente === 1) {
            setSiguiente(38)
        } else {
            setSiguiente(Siguiente - 1)
            $(`#imagen${Siguiente}`).removeClass("activado");
        }

    }

    return (
        <>
            <div className="experienciaContainer" id="experienciaContainer">
                <div className="experienciaItem">
                    <div className="experienciaItemIndicador">
                        <p> {Siguiente} de 38</p>
                    </div>

                    <div className="experienciaTitulo">
                        <h1>Ganadores<br /> Experiencias<br />  Innovadoras</h1>
                        <div>
                            <button onClick={atras}>{prev}</button>
                            <button onClick={siguiente}>{next}</button>
                        </div>
                    </div>
                    <div className="experienciaItems">
                        <img id={`imagen${Siguiente}`} src={cargarImagen(`./${Siguiente}.jpg`)} alt="Imagenes Ganadores Experiencias Innovadoras"></img>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Experiencia;