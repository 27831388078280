import React, { useEffect } from "react";
import imagen1  from "./Fotos/FOTO-CESAR-DARIO.png";
import imagen2 from "./Fotos/FOTO-JHON-JAMES-GOMEZ.png";
import imagen3 from "./Fotos/FOTO-HANEY-AGUIRRE.png";
import imagen4 from "./Fotos/FOTO-EUCLIDES-MURCIA.png";
import imagen5 from "./Fotos/FOTO-ANGIE-SALAZAR.png";
import imagen6 from "./Fotos/FOTO-VIVIANA-ORTIZ.png";
import imagen7 from "./Fotos/FOTO-ANDREA.png";
import imagen8 from "./Fotos/FOTO-VALENTINA-GUERRERO.png";
import imagen9 from "./Fotos/FOTO-CARLOS-FORERO.png";
import imagen10 from "./Fotos/FOTO-MARIANA-PABA.png";

import $ from "jquery";
import "./Slider.css"

const Slider = () => {

    const items = [

        {
            foto: imagen1,
            desc: "Licenciado en Comunicación e Informática Educativas y Magíster en Entornos Virtuales de Aprendizaje. Docente adscrito a la Facultad de Ciencias de la Educación de la Universidad Tecnológica de Pereira desde hace 13 años. Con una experiencia en el campo de la docencia por más de 16 años. Se ha desempeñado como Investigador, experto pedagógico en contenidos educativos, docente virtual, presencial e híbrido y diseñador instruccional. Ha abordado el mundo del audiovisual desde lo formativo y la producción, desde diferentes campos y proyectos. En la actualidad es el coordinador general del colectivo Imparciales, red de acompañamiento docente.",
            id: "1",
            contenedor: "contenedor1",
            fotito: "fotito1",
            active: "foto1",
            animacion: "desc1",
            persona: "César Darío Salazar Ríos"
        },
        {
            foto: imagen2,
            desc: "Doctor en Psicología, UBA, Argentina. Magíster en Sociología, Universidad del Valle. Psicólogo, Universidad Cooperativa de Colombia. Docente e investigador en instituciones de Colombia y Argentina desde 2002. Investigador del Grupo Clínica y Salud Mental de la UCP. Algunas publicaciones recientes: Violencia sexual, fantasma y posición subjetiva: El caso de una excombatiente de las FARC-EP (2023); Jouissance and Passage to Civil Life: The Case of a Former FARC-EP Combatant (2024); El anhelo fascista y el odio como capital político: reflexiones desde el psicoanálisis (2024); entre otras.",
            id: "2",
            contenedor: "contenedor2",
            fotito: "fotito2",
            active: "foto2",
            animacion: "desc2",
            persona: "John James Gómez"
        },
        {
            foto: imagen3,
            desc: "Licenciado en educación física y deportes, Universidad de Caldas, Psicólogo, Universidad de Manizales , Magister en Psicología, Universidad del Valle, y actualmente, cursa el doctorado en Salud, Psicología y Psiquiatría (Universidad de Almería, España). Ha sido autor-coautor y revisor de varios artículos publicados en revistas de impacto. Su trayectoria investigativa es reconocida por Minciencias como Investigador Asociado. Participa activamente en redes y grupos de investigación nacionales e internacionales. Recientemente fue coordinador nacional del Nodo de Investigación de Psicología del Deporte y Actividad Física de la Asociación Colombiana de Facultades de Psicología. Es docente-investigador con más de diez años experiencia a nivel de pregrado y postgrado.",
            id: "3",
            contenedor: "contenedor3",
            fotito: "fotito3",
            active: "foto3",
            animacion: "desc3",
            persona: "Hector Haney Aguirre"
        },
        {
            foto: imagen4,
            desc: "Licenciado en matemáticas y computación, especialista en administración  de la informática  Educativa, magíster  en enseñanza de las matemáticas, Dr en Educación, Docente Investigador en Ciencias  Sociales y Aplicadas de la UCP. Master Teacher de  Intel Corporation, docente en diferentes especializaciones y maestrías de la UCP. Docente de Básica y Media de la secretaría de educación del municipio de Pereira.",
            id: "4",
            contenedor: "contenedor4",
            fotito: "fotito4",
            active: "foto4",
            animacion: "desc4",
            persona: "Euclides Murcia"
        },
        {
            foto: imagen5,
            desc: "Experta en comunicación y estrategia de marca. Es comunicadora Social y Periodista con experiencia en relaciones públicas, marketing digital y comunicación estratégica a nivel nacional y regional. Directora de comunicación, consultora y docente universitaria, experta en la creación y ejecución de estrategias comunicativas para diversos sectores. Magíster en Marketing, Emprendimiento e innovación de la Universidad del Rosario y Liderazgo internacional de la Universidad EADA de Barcelona. Certificada en Marketing & value management de la University of Albany, New York.",
            id: "5",
            contenedor: "contenedor5",
            fotito: "fotito5",
            active: "foto5",
            animacion: "desc5",
            persona: "Angie Salazar"
        },
        {
            foto: imagen6,
            desc: 'Licenciada de la Universidad de Antioquia, especialista en Edumática, Magíster en Pedagogía y Desarrollo Humano, Magíster en Innovación Educativa.  Coordinadora del Centro de Innovación Educativa y docente de la Universidad Católica de Pereira. Con experiencia en diseño, planeación, ejecución y administración de la generación de estrategias y proyectos para el desarrollo de Competencias TIC, habilidades digitales, diseño instruccional y apropiación de metodologías activas que posibilitan la innovación en el aula. Apasionada por la educación, especialmente la educación virtual. Con experiencia en educación inicial y primaria.',
            id: "6",
            contenedor: "contenedor6",
            fotito: "fotito6",
            active: "foto6",
            animacion: "desc6",
            persona: "Viviana Lorena Ortiz Villada"
        },
        {
            foto: imagen7,
            desc: "Licenciada en Comunicación e Informática Educativa y Comunicadora Social, Magíster en Comunicación Transmedia. Actualmente, es asesora pedagógica del Centro de Innovación Educativa de la Universidad Católica de Pereira. Cuenta con experiencia en el trabajo con Instituciones de Educación Superior en construcción de ambientes virtuales de aprendizaje, formación a comunidad sobre competencias TIC y habilidades digitales, también ha trabajado en procesos comunitarios y ha desarrollado proyectos vinculados al ámbito educativo, de comunicación e investigación.",
            id: "7",
            contenedor: "contenedor7",
            fotito: "fotito7",
            active: "foto7",
            animacion: "desc7",
            persona: "Andrea Carolina Londoño"
        },
        {
            foto: imagen8,
            desc: "Comunicadora Social - Periodista, Especialista en gerencia de comunicación corporativa, con estudios en liderazgo, planeación y ejecución estratégica, gestión del cambio, propiedad intelectual y derechos de autor. Cuenta con más de 6 años de experiencia en empresas privadas en áreas como comunicación, mercadeo, desarrollo y capacitación. Actualmente es asesora en calidad de contenidos y apoya a la comunidad educativa en la calidad y ética que requieren los procesos de enseñanza.",
            id: "8",
            contenedor: "contenedor8",
            fotito: "fotito8",
            active: "foto8",
            animacion: "desc8",
            persona: "Valentina Guerrero"
        },
        {
            foto: imagen9,
            desc: "Licenciado en Comunicación e Informática Educativa y Magíster en Comunicación Digital. Experto en E-learning (Diseño Instruccional). Docente catedrático en el área de tecnología e innovación. Experiencia en alfabetización digital e inteligencia artificial.",
            id: "9",
            contenedor: "contenedor9",
            fotito: "fotito9",
            active: "foto9",
            animacion: "desc9",
            persona: "Carlos Enrique Forero"
        },
        {
            foto: imagen10,
            desc: "Licenciada en Comunicación e Informática Educativa y estudiante de maestría en educación con formación en TIC para la educación y educación virtual. Experiencia en diseño instruccional y creación de contenido educativo para redes sociales.",
            id: "10",
            contenedor: "contenedor10",
            fotito: "fotito10",
            active: "foto10",
            animacion: "desc10",
            persona: "Mariana Paba"
        },

    ];

    useEffect(() => {

        const Contenedor1 = document.getElementById("fotito1")
        const Contenedor2 = document.getElementById("fotito2")
        const Contenedor3 = document.getElementById("fotito3")
        const Contenedor4 = document.getElementById("fotito4")
        const Contenedor5 = document.getElementById("fotito5")
        const Contenedor6 = document.getElementById("fotito6")
        const Contenedor7 = document.getElementById("fotito7")
        const Contenedor8 = document.getElementById("fotito8")
        const Contenedor9 = document.getElementById("fotito9")
        const Contenedor10 = document.getElementById("fotito10")

        const AnimacionSlider1 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto1").addClass("active");
                    $("#desc1").addClass("activo");
                    $("#desc11").addClass("activo");


                } else {
                    $("#foto1").removeClass("active");
                    $("#desc1").removeClass("activo");
                    $("#desc11").removeClass("activo");

                }
            });
        }

        const AnimacionSlider2 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto2").addClass("active");
                    $("#desc2").addClass("activo");
                    $("#desc21").addClass("activo");


                } else {
                    $("#foto2").removeClass("active");
                    $("#desc2").removeClass("activo");
                    $("#desc21").removeClass("activo");

                }
            });
        }

        const AnimacionSlider3 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto3").addClass("active");
                    $("#desc3").addClass("activo");
                    $("#desc31").addClass("activo");


                } else {
                    $("#foto3").removeClass("active");
                    $("#desc3").removeClass("activo");
                    $("#desc31").removeClass("activo");

                }
            });
        }

        const AnimacionSlider4 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto4").addClass("active");
                    $("#desc4").addClass("activo");
                    $("#desc41").addClass("activo");


                } else {
                    $("#foto4").removeClass("active");
                    $("#desc4").removeClass("activo");
                    $("#desc41").removeClass("activo");

                }
            });
        }

        const AnimacionSlider5 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto5").addClass("active");
                    $("#desc5").addClass("activo");
                    $("#desc51").addClass("activo");


                } else {
                    $("#foto5").removeClass("active");
                    $("#desc5").removeClass("activo");
                    $("#desc51").removeClass("activo");

                }
            });
        }

        const AnimacionSlider6 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto6").addClass("active");
                    $("#desc6").addClass("activo");
                    $("#desc61").addClass("activo");


                } else {
                    $("#foto6").removeClass("active");
                    $("#desc6").removeClass("activo");
                    $("#desc61").removeClass("activo");

                }
            });
        }

        const AnimacionSlider7 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto7").addClass("active");
                    $("#desc7").addClass("activo");
                    $("#desc71").addClass("activo");


                } else {
                    $("#foto7").removeClass("active");
                    $("#desc7").removeClass("activo");
                    $("#desc71").removeClass("activo");

                }
            });
        }

        const AnimacionSlider8 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto8").addClass("active");
                    $("#desc8").addClass("activo");
                    $("#desc81").addClass("activo");


                } else {
                    $("#foto8").removeClass("active");
                    $("#desc8").removeClass("activo");
                    $("#desc81").removeClass("activo");

                }
            });
        }

        const AnimacionSlider9 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto9").addClass("active");
                    $("#desc9").addClass("activo");
                    $("#desc91").addClass("activo");


                } else {
                    $("#foto9").removeClass("active");
                    $("#desc9").removeClass("activo");
                    $("#desc91").removeClass("activo");

                }
            });
        }

        const AnimacionSlider10 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto10").addClass("active");
                    $("#desc10").addClass("activo");
                    $("#desc101").addClass("activo");


                } else {
                    $("#foto10").removeClass("active");
                    $("#desc10").removeClass("activo");
                    $("#desc101").removeClass("activo");

                }
            });
        }

        const observador1 = new IntersectionObserver(AnimacionSlider1, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador2 = new IntersectionObserver(AnimacionSlider2, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador3 = new IntersectionObserver(AnimacionSlider3, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador4 = new IntersectionObserver(AnimacionSlider4, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador5 = new IntersectionObserver(AnimacionSlider5, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador6 = new IntersectionObserver(AnimacionSlider6, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });
        const observador7 = new IntersectionObserver(AnimacionSlider7, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador8 = new IntersectionObserver(AnimacionSlider8, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador9 = new IntersectionObserver(AnimacionSlider9, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador10 = new IntersectionObserver(AnimacionSlider10, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        observador1.observe(Contenedor1);
        observador2.observe(Contenedor2);
        observador3.observe(Contenedor3);
        observador4.observe(Contenedor4);
        observador5.observe(Contenedor5);
        observador6.observe(Contenedor6);
        observador7.observe(Contenedor7);
        observador8.observe(Contenedor8);
        observador9.observe(Contenedor9);
        observador10.observe(Contenedor10);
    }, [])



    function Atras(e) {

        if (e.target.id === "0") {
            $(".sliderContainer").css("order", "")
            $(`#contenedor${items.length}`).css("order", "-1")
            console.log(items.length)
        } else {
            $(".sliderContainer").css("order", "")
            $(`#contenedor${e.target.id}`).css("order", "-1")
        }
    }

    const Siguiente = (e) => {
        $(".sliderContainer").css("order", "")
        $(`#contenedor${e.target.id}`).css("order", "-1")
    }

    const sig = ">"
    const pre = "<"

    return (
        <>
            <div style={{ position: "relative" }}>

                <div className="slider" id="slider">
                    {
                        items.map((user) => (

                            <div className="sliderContainer" id={user.contenedor}>
                                <div className="containerFoto" id={user.fotito}>
                                    <div style={{ width: "100%", height: "calc(100%)", display: "flex", justifyContent: "center" }}>
                                        <img src={user.foto} alt="foto" id={user.active}></img>
                                    </div>

                                </div>
                                <div className="containerDescripcion">
                                    <h1 style={{textAlign: "center"}} id={user.animacion + 1}> {user.persona}</h1>
                                    <p id={user.animacion}>{user.desc}</p>
                                    <div>
                                        <button onClick={Atras} id={Number(user.id) - Number(1)}>{pre}</button>
                                        <button onClick={Siguiente} id={Number(user.id) + Number(1)}>{sig}</button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
                <div className="Borde2">
                    <div className="Borde">
                        <div className="tituloTalleristas">
                            <h1>Talleristas</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Slider;