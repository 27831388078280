import React, { useEffect, useRef, useState } from "react";
import "./Video.css"
import imagen from "./Miniaturas/Minitatura0.png"
import herramienta from "./Miniaturas/Minitatura3.png"
import gestionar from "./Miniaturas/Minitatura1.png"
import herramientasvirtuales from "./Miniaturas/Minitatura2.png"
import powerpoint from "./Miniaturas/Minitatura4.png"
import word from "./Miniaturas/Minitatura5.png"
import produccionvideo from "./Miniaturas/ProduccionVideo.png"
import estetica from "./Miniaturas/CreatividadEstetica.png"
import herramientasaprendizaje from "./Miniaturas/HerramientasAprendizaje.png"
import seguridadinformatica from "./Miniaturas/SeguridadInformatica.png"
import clasesvirtuales from "./Miniaturas/Metaverso.png"
import derechosautor from "./Miniaturas/DerechoAutor.png"
import referenciabibliografica from "./Miniaturas/Referenciac.png"
import { FiPlay, FiChevronRight, FiChevronLeft, FiPause } from "react-icons/fi";
import $ from "jquery"
import ReactPlayer from 'react-player'

function Video() {

    const [video, setvideo] = useState("")
    const [play, setPlay] = useState(false);
    const [Mute, setMute] = useState(false);

    const [Pixels, setPixels] = useState(0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);


    const Listas = [
        {
            video: "MKtnRQfYZt8",
            id: "video1",
            foto: produccionvideo
        },
        {
            video: "99xq7HPngeo",
            id: "video2",
            foto: estetica
        },
        {
            video: "OJi6CDIVFhg",
            id: "video3",
            foto: herramientasaprendizaje
        },

        {
            video: "ZLc21Re8zs8",
            id: "video5",
            foto: clasesvirtuales
        },
        {
            video: "OZUwxRVUO9M",
            id: "video6",
            foto: derechosautor
        },
        {
            video: "fXzggaiQSVQ",
            id: "video4",
            foto: seguridadinformatica
        },
        {
            video: "rQEQ_JCD0aA",
            id: "video7",
            foto: referenciabibliografica
        },

        {
            video: "FHWAo1ae2e4",
            id: "video8",
            foto: imagen
        },
        {
            video: "n3PhO67f524",
            id: "video9",
            foto: herramienta
        },
        {
            video: "b5TFzkC-SSg",
            id: "video10",
            foto: gestionar
        },
        {
            video: "b1RJS_4rWY8",
            id: "video11",
            foto: powerpoint
        },
        {
            video: "sykji5BQ4W8",
            id: "video12",
            foto: word
        },
        {
            video: "jv4EupjHIHY",
            id: "video13",
            foto: herramientasvirtuales

        },
    ]



    useEffect(() => {
        $(".play").on("click", function () {
            setvideo(this.id)

        });

        $(".playimagen").on("click", function () {
            setvideo(this.id)

        });
    }, [video])


    function Mover() {
        setMute(true);
        console.log(Pixels)
        console.log(windowSize.current[0])
        setPlay(false)
        $(".videosItems").css("visibility", "visible")
        $(".status").css("display", "none")
        $(".videosContainer").css("height", "")

        let dimensiones = 0
        if (windowSize.current[0] > 600) {
            dimensiones = 400
        } else {
            dimensiones = 405

        }
        if (Pixels === -4800) {
            $(".videosItems").css(`transform`, `translateX(0px)`)
            setPixels(0)
        } else if (Pixels === -4860) {
            $(".videosItems").css(`transform`, `translateX(0px)`)
            setPixels(0)

        }
        else {
            $(".videosItems").css("transition", ".7s")

            $(".videosItems").css(`transform`, `translateX(${Pixels + -dimensiones}px)`)
            setPixels(Pixels + -dimensiones)

        }

    }

    function Retroceder() {
        $(".status").css("display", "none")
        setMute(true);
        setPlay(false)
        $(".videosContainer").css("height", "")

        $(".videosItems").css("visibility", "visible")
        console.log(Pixels)

        let dimensiones = 0
        if (windowSize.current[0] > 600) {
            dimensiones = 400
        } else {
            dimensiones = 410

        }
        if (Pixels === -0) {
            setPixels(0)
        } else if (Pixels === -0) {
            setPixels(0)

        }
        else {
            $(".videosItems").css("transition", ".7s")

            $(".videosItems").css(`transform`, `translateX(${Pixels + +dimensiones}px)`)
            setPixels(Pixels + dimensiones)

        }
    }

    useEffect(() => {
        if (windowSize.current[0] > 600) {

            if (Pixels === 0) {
                $(".play").attr("id", Listas[0].video)
            }
            else if (Pixels === -400) {
                $(".play").attr("id", Listas[1].video)
            } else if (Pixels === -800) {
                $(".play").attr("id", Listas[2].video)
            } else if (Pixels === -1200) {
                $(".play").attr("id", Listas[3].video)
            } else if (Pixels === -1600) {
                $(".play").attr("id", Listas[4].video)
            } else if (Pixels === -2000) {
                $(".play").attr("id", Listas[5].video)
            } else if (Pixels === -2400) {
                $(".play").attr("id", Listas[6].video)
            } else if (Pixels === -2800) {
                $(".play").attr("id", Listas[7].video)
            } else if (Pixels === -3200) {
                $(".play").attr("id", Listas[8].video)
            } else if (Pixels === -3600) {
                $(".play").attr("id", Listas[9].video)
            } else if (Pixels === -4000) {
                $(".play").attr("id", Listas[10].video)
            } else if (Pixels === -4400) {
                $(".play").attr("id", Listas[11].video)
            } else if (Pixels === -4800) {
                $(".play").attr("id", Listas[12].video)
            }

        } else {
            if (Pixels === 0) {
                $(".play").attr("id", Listas[0].video)
            }
            else if (Pixels === -405) {
                $(".play").attr("id", Listas[1].video)
            } else if (Pixels === -810) {
                $(".play").attr("id", Listas[2].video)
            } else if (Pixels === -1215) {
                $(".play").attr("id", Listas[3].video)
            } else if (Pixels === -1620) {
                $(".play").attr("id", Listas[4].video)
            } else if (Pixels === -2025) {
                $(".play").attr("id", Listas[5].video)
            } else if (Pixels === -2430) {
                $(".play").attr("id", Listas[6].video)
            } else if (Pixels === -2835) {
                $(".play").attr("id", Listas[7].video)
            } else if (Pixels === -3240) {
                $(".play").attr("id", Listas[8].video)
            } else if (Pixels === -3645) {
                $(".play").attr("id", Listas[9].video)
            } else if (Pixels === -4050) {
                $(".play").attr("id", Listas[10].video)
            } else if (Pixels === -4455) {
                $(".play").attr("id", Listas[11].video)
            } else if (Pixels === -4860) {
                $(".play").attr("id", Listas[12].video)
            }
        }
    }, [Pixels])



    function close_video() {
        $(".videosItems").css("visibility", "hidden")

        if (play === false) {
            setTimeout(function () {
                setPlay(true)
                $(".status").css("display", "block")
                $(".videosContainer").css("height", "450px")
                let tiempo = 0;
                $("html, body").animate({ scrollTop: $(".videoTitulo").offset().top }, tiempo);
                setMute(false)
            }, 700);
        } else {
            setPlay(false)
            let tiempo = 0;

            $("html, body").animate({ scrollTop: $(".videoTitulo").offset().top }, tiempo);

        }
    }



    return (
        <>
            <head>
                <script src="https://www.youtube.com/iframe_api"></script>
            </head>
            <div className="videoTitulo">
                <h1>Videos</h1>
            </div>
            <div className="videosContainer" id="videosContainer">
                <div className="videoFondo">
                    <ReactPlayer className="status" width={"100%"} height={"100%"} muted={Mute} playing={play} url={`https://www.youtube.com/watch?v=${video}`} />
                </div>
                <div className="videosContenido">
                    <div className="videosItems">
                        {
                            Listas.map((items) => (
                                <img onClick={close_video} className="Video playimagen" id={items.video} src={items.foto} alt="..."></img>
                            ))
                        }
                    </div>
                    <div className="videosBotones">
                        {
                            play === true ? <button onClick={close_video} className="play" ><FiPause /></button> : <button onClick={close_video} className="play" >< FiPlay /></button>
                        }
                        <button onClick={Retroceder}><FiChevronLeft /></button>
                        <button onClick={Mover}> <FiChevronRight /></button>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Video;