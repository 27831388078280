import React from "react";
import "./Card.css"
import $ from "jquery";
const Carta = () => {


    /*Inteligencia Artificial*/
    $(document).ready(function () {
        $("#eduv").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>' +
                      '<li>Aprendizaje a la medida: Estrategias para personalizar la educación con IA.</li>' +
                      '<li>Habilidades digitales 360: Impulsa tu enseñanza con tecnología.</li>' +
                      '<li>Supera los retos digitales: Aprende las habilidades que marcan la diferencia.</li>' +
                      '<li>Encuentra información como todo un experto.</li>' +
                      '</ul>');
        });
    });

    /*Habilidades Comunicativas*/
    $(document).ready(function () {
        $("#dea").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>'+
                '<li>Comunicación educativa impactante: Estrategias para conectar y enseñar.</li>' +
                '<li>Domina la palabra escrita: Taller de redacción efectiva.</li>' +
                '<li>Crea tu huella digital: Estrategias de marketing para creadores.</li>' +
                '<li>Comunica y conquista: Técnicas de comunicación verbal y no verbal.</li>' +
                '</ul>');
        });
    });

    /* Aprendizaje */
    $(document).ready(function () {
        $("#si").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>'+
                '<li>Crea y conecta: Diseña recursos, evaluaciones y retroalimentación interactiva.</li>'+
                '<li>Bienestar emocional en la U: Estrategias para combatir el cansancio mental.</li>'+
                '<li>Aprendizaje autónomo: Construye tu propio camino al éxito.</li>'+
                '</ul>');
        });
    });

  /*Investigación y analitica de datos*/   
    $(document).ready(function () {
        $("#pc").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>'+
                '<li>Explora tu potencial con datos: Power BI.</li>'+
                '<li>Transforma tus ideas en publicaciones: Taller de redacción científica y elección metodológica.</li>'+
                '<li>Atlas.ti en acción: Analiza Datos como un experto.</li>'+
                '<li>Publicar con propósito: Taller de selección de revistas.</li>'+
                '<li>Pitch de investigación: Domina el arte de presentar tus ideas.</li>'+
                '</ul>');
        });
    });
/*--------------------------------------------------------------------------------*/
/*Tarjetas con sus respectivos talleres*/
/*-------------------------------------------------------------------------------*/


    const Lista = [

        /*Inteligencia Artificial*/
        {
            nombre: " Inteligencia ",
            nombre2: "artificial",

            clase: "eduv"
        },

        /*Habilidades comunicativas*/
        {
            nombre: "Habilidades  ",
            nombre2: "comunicativas",

            clase: "dea"
        },

        /*Investigación y analítica de datos*/
        {
            nombre: "Investigación y analítica de datos",

            clase: "pc"
        },

        /*Aprendizaje*/
        {
            nombre: "Aprendizaje",

            clase: "si"
        }

        
    ]

    return (
        <>
            <h1 className="tituloCartas">Categorías</h1>

            <div className="containerCartas" id="containerCartas">
                <div className="cartasDesc">
                    <div className="tallerescorrespondientes">

                    </div>
                </div>
                <div className="cartasCard">
                    {
                        Lista.map((item) => (
                            <div id={item.clase}>
                                <h3>{item.nombre} <br /> {item.nombre2}</h3>

                                <button >Talleres</button>
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default Carta;