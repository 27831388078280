import './App.css';
import Atencion from './Componentes/Atencion/Atencion';
import Calendario from './Componentes/Calendarios/Calendario';
import Carta from './Componentes/Card/Card';
import Experiencia from './Componentes/Experiencias/Experiencia';
import Header from './Componentes/Header/Header';
import Main from './Componentes/Main/Main';
import Sistema from './Componentes/Sistematizacion/Sistema';
import Slider from './Componentes/Slider/Slider';
import Video from './Componentes/Videos/Video';
import video from "./fondo.mp4"
function App() {

  return (


    <>
      <div className='fondo'>
        <video autoPlay loop muted preload="metadata">
          <source src={video} />
        </video>
      </div>
      <Header />
      <Main />
      <Carta />
      {/* <Calendario /> */}

      <Slider />
      {/* <Sistema /> */}
      <Experiencia />
      <Video />
      <Atencion />
    </>
  );
}

export default App;
